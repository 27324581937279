<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

import {
  required
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Create Category",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Form Create Category",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Categories",
          href: "/category"
        },
        {
          text: "Create Category",
          active: true
        }
      ],
      form: {
        name: null,
        description: null,
        icon: null,
        image: null,
        order: 1,
        status: null,
      },
      typesubmit: false,
      isError: false,
      errorMessage: null,
      options: [
        { name: 'Active', value: 1 },
        { name: 'Not Active', value: 0 }
      ],
      isStatusNotSelected: false,
    };
  },
  validations: {
    form: {
      name: { required },
      order: { required }
    }
  },
  methods: {
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {
        this.isStatusNotSelected = (this.form.status === null)? true : false

        if(this.isStatusNotSelected) {
          return false
        }
        // do your submit logic here
        let param = new FormData();
        param.append('name', this.form.name)
        param.append('status', this.form.status.value)
        param.append('description', this.form.description)
        param.append('icon', this.form.icon)
        param.append('image', this.form.image)
        param.append('order', this.form.order)

        this.$axios.post('/category', param)
        .then( (result) => { 
          console.log(result.data)
          Swal.fire("Success", "Category Created Successfully", "success");
          this.$router.push('/category')
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },

    upload(event) {
      if(event.target.files[0] !== undefined){
        let extension = event.target.files[0].name.split(".").pop()
        let allowedExtention = ['jpg','jpeg','png','svg']
        if(allowedExtention.includes(extension.toLowerCase())){
          this.form.icon = event.target.files[0]
          this.errorMessage = null
          this.isError = false
        }else{
          this.isError = true
          this.errorMessage = "icon image extentions not allowed"
        }
      }
    },

    uploadImage(event) {
      if(event.target.files[0] !== undefined){
        let extension = event.target.files[0].name.split(".").pop()
        let allowedExtention = ['jpg','jpeg','png','svg']
        if(allowedExtention.includes(extension.toLowerCase())){
          this.form.image = event.target.files[0]
          this.errorMessage = null
          this.isError = false
        }else{
          this.isError = true
          this.errorMessage = "icon image extentions not allowed"
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  :class="{ 'is-invalid': typesubmit && $v.form.name.$error }"
                />
                <div v-if="typesubmit && $v.form.name.$error" class="invalid-feedback">
                  <span v-if="!$v.form.name.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Description</label>
                <textarea
                  v-model="form.description"
                  class="form-control"
                  name="description"
                ></textarea>
              </div>

              <div class="form-group">
                <label>Image</label>
                <input type="file" id="image" ref="image" accept=".jpg, .jpeg, .png, .svg" @change="uploadImage" class="form-control" name="image" />
              </div>

              <div class="form-group">
                <label>Icon</label>
                <input type="file" id="icon" ref="icon" accept=".jpg, .jpeg, .png, .svg" @change="upload" class="form-control" name="icon" />
              </div>

              <div class="form-group">
                <label>Ordering</label>
                <input
                  v-model="form.order"
                  type="number"
                  min="0"
                  step="1"
                  class="form-control"
                  name="order"
                  :class="{ 'is-invalid': typesubmit && $v.form.order.$error }"
                />
                <div v-if="typesubmit && $v.form.order.$error" class="invalid-feedback">
                  <span v-if="!$v.form.order.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Status</label>
                <multiselect :class="{ 'is-invalid': isStatusNotSelected }" v-model="form.status" deselect-label="Can't remove this value" track-by="name" label="name" placeholder="Select one" :options="options" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                </multiselect>
                <div v-if="isStatusNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>